import { ChangePasswordFormData } from '@shared/schemas';

export const passwordInputs: {
  id: keyof ChangePasswordFormData;
  label: string;
  tooltip?: string;
}[] = [
  {
    id: 'oldPassword',
    label: 'Old password',
  },
  {
    id: 'newPassword',
    label: 'New password',
    tooltip:
      'Please create a password with 8-40 characters, including at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.',
  },
  {
    id: 'confirmedPassword',
    label: 'Repeat password',
  },
];

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { PasswordInput, PasswordInputWithValidation } from '@components/form/passwordInput';

import { MAX_PASSWORD_LENGTH } from '@shared/constants';
import { ChangePasswordFormData, changePasswordSchema } from '@shared/schemas';

import Modal from '../Modal';
import ModalFooter from '../ModalFooter';
import { passwordInputs } from './constants';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onSubmit: SubmitHandler<ChangePasswordFormData>;
  isLoading: boolean;
}

const ChangePasswordModal = ({
  isModalOpen,
  setIsModalOpen,
  onSubmit,
  isLoading,
}: IProps) => {
  const methods = useForm<ChangePasswordFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(changePasswordSchema),
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    reset,
  } = methods;

  return (
    <Modal
      isOpen={isModalOpen}
      disableClose={isLoading}
      onClose={reset}
      setIsOpen={setIsModalOpen}
      modalTitle="Change Password"
      className="!max-w-25.5rem !overflow-visible"
      modalContentClassName="!overflow-visible"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {passwordInputs.map(({ id, label, tooltip }) => {
            return id === 'newPassword' ? (
              <PasswordInputWithValidation
                id={id}
                label={label}
                error={errors[id]?.message}
                register={register(id)}
                disabled={false}
                autoresize
                required
                maxLength={MAX_PASSWORD_LENGTH}
                type="password"
              />
            ) : (
              <PasswordInput
                id={id}
                register={register(id)}
                label={label}
                error={errors[id]?.message}
                hideIsOptional
                autoResize
                tooltip={tooltip}
                key={id}
                required
                maxLength={MAX_PASSWORD_LENGTH}
              />
            );
          })}
          <ModalFooter
            cancelButtonProps={{
              text: 'Cancel',
              onClick: () => {
                reset();
                setIsModalOpen(false);
              },
            }}
            confirmButtonProps={{
              text: 'Change password',
              isDisabled: isLoading || isSubmitting,
              type: 'submit',
            }}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ChangePasswordModal;
